import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, Button, Container } from '@mui/material';
// components
import Page from 'components/Page';
import { MotionContainer, varBounce } from 'components/animate';
// assets
import { MaintenanceIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function PremissionAccess() {
  return (
    <Page title="خطای دسترسی" sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                خطا در ورود به پنل ادمین
              </Typography>
            </m.div>
            <Typography sx={{ color: 'text.secondary' }}>
              متاسفانه شما برای ورود به پنل ادمین احراز هویت نشده اید لطفا مجدد امتحان کنید
            </Typography>

            <m.div variants={varBounce().in}>
              <MaintenanceIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </m.div>

            <Button href="https://changefa.net/auth/login" size="large" variant="contained">
              رفتن به صفحه ورود
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
