import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import api from '../../../utils/axios';
import { endpoints } from '../../../data/endpoints';
import { dispatch } from '../../../redux/store';
import { setSession } from '../../../utils/jwt';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { getUserProfile } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('وارد کردن نام کاربری الزامیست'),
    password: Yup.string().required('وارد کردن رمز عبور الزامیست'),
  });

  const defaultValues = {
    phoneNumber: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      api
        .post(endpoints.auth.signin, {
          phoneNumber: data.phoneNumber,
          password: data.password,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            localStorage.setItem('token', response.data.data.accessToken);
            localStorage.setItem('ref', response.data.data.refreshToken);
            localStorage.setItem('expires', response.data.data.expires);
            setSession(response.data.data.accessToken);

            getUserProfile();
            dispatch({ type: 'LOGIN', payload: {} });
          } else {
            setError('afterSubmit', { message: response.data.message });
          }
        })
        .catch((error) => {
          setError('afterSubmit', { ...error, message: error.message });
        });
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField
          name="phoneNumber"
          label="نام کاربری"
          inputProps={{
            dir: 'ltr',
          }}
        />

        <RHFTextField
          name="password"
          label="رمز عبور"
          type={showPassword ? 'text' : 'password'}
          inputProps={{
            dir: 'ltr',
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          رمز عبور را فراموش کردم؟
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        ورود
      </LoadingButton>
    </FormProvider>
  );
}
