import { useEffect, useState } from 'react';
import { authApi } from '../utils/axios';
import { endpoints } from '../data/endpoints';

const useFetchPendingTrCount = () => {
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPendingTransactionsCount = async () => {
      try {
        const response = await authApi.get(`${endpoints.PendingTransactionsCount.get}`);
        if (response.data.statusCode === 200) {
          setCount(response.data?.data ?? 0); // Fallback to 0 if data is undefined or null
        } else {
          setError(`Unexpected status code: ${response.data.statusCode}`);
        }
      } catch (err) {
        setError(err.message);
        console.error(err);
      }
    };

    fetchPendingTransactionsCount();
  }, []);

  return { count, error };
};

export default useFetchPendingTrCount;
