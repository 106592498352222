import { authApi } from '../axios';

export const getPermissions = async () => {
  try {
    const response = await authApi.get('/user-permission');

    if (response.status === 200) {
      const { data } = response.data;
      return data;
    }
    return response;
  } catch (error) {
    console.error('Error fetching permissions: ', error);
  }
};
