import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function formatSymbol(symbol) {
  switch (symbol) {
    case 'IRT':
      return 'تومان';

    case 'USDT':
      return 'تتر';
    
      case 'PAY':
        return 'پرداخت ارزی';

    default:
      return symbol;
  }
}

export function fTransactionAmount(amount, symbol) {
  return `${(+(amount || 0).toFixed(symbol === 'IRT' ? 0 : 2)).toLocaleString()  } ${  formatSymbol(symbol)}`;
}