// PermissionsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getPermissions } from '../utils/apis/getPermissions';
import useAuth from '../hooks/useAuth';

const PermissionsContext = createContext();

export const usePermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  const [userType, setUserType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [permissionsFromServer, setPermissionsFromServer] = useState([]);
  const [permissionsFromServerTemp, setPermissionsFromServerTemp] = useState([
    // __New__
    // {
    //   id: 'c3a8ce94-e3ea-47c2-b347-de225cbb625f',
    //   claim: '/currency-gateway/exchange',
    //   displayText: 'ویرایش تبادل ارز',
    // },
    // {
    //   id: '0e1701c0-7a31-49f6-9948-7f1be01c87b9',
    //   claim: '/exchange/currency-gateway',
    //   displayText: 'افزودن تبادل ارز',
    // },
    // {
    //   id: '861ccba7-6695-4b62-944d-6dc280b82b3b',
    //   claim: '/exchange/currency-gateway/{id:long}',
    //   displayText: 'حذف تبادل ارز',
    // },
    // {
    //   id: '8d201d5f-1697-4da4-8ab3-bdfc1df547cb',
    //   claim: '/gateway-account-setting/edit',
    //   displayText: 'ویرایش تنظیمات حساب دروازه',
    // },
    // {
    //   id: 'c58ace5e-eb66-4600-aa74-3864043ffee3',
    //   claim: '/level/admin/exchange/edit',
    //   displayText: 'ویرایش ادمین سطح معاملات',
    // },
    // {
    //   id: 'c905e00b-71be-4857-9cf4-20107080f152',
    //   claim: '/level/admin/exchange/{Id}',
    //   displayText: 'ادمین سطح معاملات',
    // },
    // {
    //   id: 'f78c6252-1094-44ee-8ec7-0a9cea765362',
    //   claim: '/system-wallet/{Symbol}',
    //   displayText: 'کیف پول سیستم',
    // },

    // {
    //   id: '0608a63f-ce86-49b8-81e9-ffc2bc9c8121',
    //   claim: '/level/denial',
    //   displayText: 'رد سطح',
    // },

    // {
    //   id: '1ef4f7e4-e61b-41cc-a996-f5fff4d5b4b4',
    //   claim: '/check-access',
    //   displayText: 'تایید دسترسی',
    // },

    // {
    //   id: 'aa0f4058-88eb-4337-a050-8fba5f62e1e8',
    //   claim: '/order/create',
    //   displayText: 'ساخت سفارش',
    // },
    // {
    //   id: 'ac39c280-81cd-4edc-ab5b-8d48760d6ebd',
    //   claim: '/level/acceptance',
    //   displayText: 'تأیید سطح',
    // },
    // {
    //   id: '951875a0-910e-4db9-be7e-e73872ce4517',
    //   claim: '/level/upgrade/list/{pageNumber}/{pageSize}',
    //   displayText: 'لیست ارتقاء سطوح',
    // },
    // {
    //   id: '0cd2b118-c4d0-4efb-846b-dcf535c30e6e',
    //   claim: '/bank/account/list/{pageNumber}/{pageSize}',
    //   displayText: 'لیست حساب‌های بانکی (صفحه {pageNumber} از {pageSize})',
    // },
    // __New__

    // ADMIN PERMISSIONS CONNECTED

    // ADMIN PERMISSIONS NOT CONNECTED

    // %% نقش ها %%
    {
      id: '51c9d8c4-6c9a-49a5-b478-22734b61f3ce',
      claim: '/role/all/{pageNumber}/{pageSize}', // Done
      displayText: 'دریافت همه نقش‌ها',
    },
    {
      id: '3235995f-690e-4534-b169-1057eb611211',
      claim: '/role-claim/add', // Done
      displayText: 'ایجاد نقش جدید',
    },
    {
      id: '385ea235-6202-4a92-8f9c-861ee0aa9c7b',
      claim: '/role-claim/detail',
      displayText: 'جزئیات نقش', // Done
    },
    {
      id: 'e7660b3c-b93f-48f8-a74a-4f1a29eb0e0d',
      claim: '/role-claim/delete', // Done
      displayText: 'حذف نقش',
    },
    {
      id: 'f8e8a771-0689-4e84-89db-137a258d98d2',
      claim: '/role-claim/edit', // Done
      displayText: 'ویرایش نقش',
    },

    // %% کاربران %%
    {
      id: 'cefadcf0-cd2a-4812-aa7c-43fb630d680a',
      claim: '/user/all/{pageNumber}/{pageSize}', // Done
      displayText: 'لیست تمام کاربران (صفحه {pageNumber} از {pageSize})',
    },
    {
      id: 'e80d3cfd-c1be-46bd-972e-538a9a1ee40b',
      claim: '/user-role/profile', // Done
      displayText: 'جزئیات نقش کاربر',
    },
    {
      id: 'e78fadfa-9be3-4351-94e1-4d8b8941767c',
      claim: '/user/edit', // Done
      displayText: 'ویرایش کاربر',
    },
    {
      id: '75c8cea1-b956-4858-bd82-cfbd59e41d4e',
      claim: '/user-role/add', // Done
      displayText: 'اضافه کردن نقش جدید به کاربر',
    },
    {
      id: '63ca9b41-f908-409e-b22f-0068c6a3b750',
      claim: '/user-role/edit', // Done
      displayText: 'ویرایش نقش های کاربر',
    },
    // %% کاربران > لیست بررسی احراز هویت %%
    {
      id: 'e1cee8b4-4642-4e72-b452-0be17e12b7b9',
      claim: '/level/manualvalidations/{pageNumber}/{pageSize}', // Done
      displayText: 'اعتبارسنجی دستی (صفحه {pageNumber} از {pageSize})',
    },
    {
      id: '0350d32a-aa2f-4a2f-9474-bee510f15e44',
      claim: '/level/manualvalidations/{id}', // ...
      displayText: 'اعتبارسنجی دستی (شناسه {id})',
    },
    {
      id: '4925ff0a-a50c-4db7-b986-bfa81fa56405',
      claim: '/level/upgrade/accept/level2', // ...
      displayText: 'تأیید ارتقاء به سطح ۲',
    },
    {
      id: '6da82f2e-5a06-46ec-aefd-72fa8ada65fa',
      claim: '/level/upgrade/deny/level2', // ...
      displayText: 'رد ارتقاء به سطح ۲',
    },
    {
      id: 'a0510928-4d90-4c63-95ff-49a84cb0f93f',
      claim: '/level/add-exchange/accept', // ...
      displayText: 'تأیید افزودن معامله',
    },
    {
      id: 'bb537e1c-dfcd-4be7-800c-aa512706fa33',
      claim: '/level/add-exchange/decline', // ...
      displayText: 'رد افزودن معامله',
    },
    {
      id: 'c586686b-3069-43a4-b063-800b27e16e4a',
      claim: '/level/become-exchanger/accept', // ...
      displayText: 'پذیرش تبدیل به صادرکننده',
    },
    {
      id: 'ea773ddd-4118-4a5b-b9da-2ee3630d7d1e',
      claim: '/level/become-exchanger/decline', // ...
      displayText: 'رد تبدیل به صادرکننده',
    },
    // %% صرافی ها %%
    {
      id: '6eb50149-9142-4848-b086-b6fbbf6d4826',
      claim: '/exchange/list/{pageNumber}/{pageSize}', // Done
      displayText: 'لیست تبادلات',
    },
    // %% حساب ها %%
    {
      id: '8a4c13f2-9622-440a-921a-21aa4de9a5e9',
      claim: '/cryptoExchange/get-all', // Done
      displayText: 'دریافت همه تبادلات رمزارز',
    },
    {
      id: '9bd7b10f-802e-4a54-9ba3-8db4a921e004',
      claim: '/cryptoExchange-account/update', // Done
      displayText: 'به‌روزرسانی حساب رمزارز تبادل',
    },
    {
      id: 'fdad1ce4-6ceb-458a-82c2-f86b70f5e186',
      claim: '/cryptoExchange/{Id}', // Done
      displayText: 'دریافت اطلاعات تبادل رمزارز با شناسه {Id}',
    },
    {
      id: '146c55a9-d63c-4d16-80b0-e72d815363ca',
      claim: '/perfect-money/get-all', // Done
      displayText: 'دریافت همه حساب‌های پرفکت‌مانی',
    },
    {
      id: 'cae025c0-d175-48d3-861b-28570da5a5ac',
      claim: '/perfect-money/{Id}', // Done
      displayText: 'دریافت اطلاعات پرفکت‌مانی با شناسه {Id}',
    },
    {
      id: 'e4e2aa98-2bf2-484f-909a-38ba0992b626',
      claim: '/perfect-money/update', // Done
      displayText: 'به‌روزرسانی پرفکت‌مانی',
    },
    // %% مارکت ها %%
    {
      id: '6fecb791-a802-46c7-a68d-b2f9eff74f7e',
      claim: '/market/list/{pageNumber}/{pageSize}', // Done
      displayText: 'لیست بازارها',
    },
    {
      id: '5b27f15d-2da7-4b35-9ffb-0fa8fcfc7184',
      claim: '/market/{symbol}', // Done
      displayText: 'دریافت اطلاعات بازار با نماد {symbol}',
    },
    {
      id: '06af1607-2d8a-4b98-9beb-38b5bd508acf',
      claim: '/market/update', // Done
      displayText: 'به‌روزرسانی بازار',
    },
    // %% سطح ها %%
    {
      id: '996ad658-a8d6-4dda-944f-528d943f7952',
      claim: '/level/list', // Done
      displayText: 'لیست سطوح',
    },
    {
      id: 'd23c4aed-ccef-43e5-8a1b-8e648fe5b3df',
      claim: '/level/update', // Done
      displayText: 'بروزرسانی سطح',
    },
    // %% درگاه های پرداخت %%
    // %% حساب های بانکی %%
    {
      id: 'f360af3a-5804-4c25-83bd-c0580e26f5c6',
      claim: '/bank/account/all/{pageNumber}/{pageSize}', // Done
      displayText: 'لیست تمام حساب‌های بانکی (صفحه {pageNumber} از {pageSize})',
    },
    // %% سفارشات %%
    {
      id: '33c833e4-05c6-4270-a96b-6b4814aa3b51',
      claim: '/order/all/{pageNumber}/{pageSize}', // ...
      displayText: 'همه سفارش‌ ها',
    },
    {
      id: 'a7be8411-020c-43fc-bc5a-48f5378766b5',
      claim: '/order/detail', // X
      displayText: 'جزئیات سفارش',
    },
    // %% تراکنش ها %%
    {
      id: '64e6c022-9270-4cf3-8f80-e4703c5bc231',
      claim: '/transaction/all/{pageNumber}/{pageSize}', // ...
      displayText: 'تمام معاملات',
    },
    {
      id: '32f18500-1dbd-4dbf-beb2-38d2fd750020',
      claim: '/transaction/detail', // X
      displayText: 'جزئیات معامله',
    },
  ]);

  // Fetch user permissions from the server
  useEffect(() => {
    const getPermissionsData = async () => {
      setIsLoading(true);
      try {
        const res = await getPermissions();
        if (res) {
          // console.log('permissions user: ', res);
          setPermissionsFromServer(res?.permissions);
        }
      } catch (error) {
        console.error('Error:', error?.message);
      } finally{
        setIsLoading(false);
      }
    };

    if (isAuthenticated) getPermissionsData();
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      setUserType(user?.phoneNumber);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const extractedPermissions = permissionsFromServer.map((permission) => permission.claim);
    setUserPermissions(extractedPermissions);
  }, [permissionsFromServer]);

  const checkPermission = (permissionClaim) => isLoading || !user ? true : userPermissions.includes(permissionClaim) || userType === 'ADMIN';

  return (
    <PermissionsContext.Provider value={{ checkPermission, userPermissions, userType }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsContext;
