import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// guards
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'role',
          children: [
            { path: 'list', element: <RoleList /> },
            { path: 'new', element: <RoleProfile /> },
            { path: ':name/edit', element: <RoleProfile /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: ':name/profile', element: <UserProfile /> },
            { path: 'authorization', element: <UserAuthorization /> },
          ],
        },
        {
          path: 'exchange',
          children: [
            { path: 'list', element: <ExchangeList /> },
            { path: 'new', element: <ExchangeCreate /> },
            { path: 'authorization-exchange', element: <UserAuthorization /> },
            { path: ':name/edit', element: <ExchangeCreate /> },
          ],
        },
        {
          path: 'account',
          children: [
            { path: 'crypto-list', element: <CryptoList /> },
            { path: ':name/profile-crypto', element: <CryptoProfile /> },
            { path: 'perfect-list', element: <PerfectMoneyList /> },
            { path: ':name/profile-pm', element: <PerfectMoneyProfile /> },
          ],
        },
        {
          path: 'market',
          children: [
            { path: 'list', element: <MarketList /> },
            { path: ':name/profile', element: <MarketProfile /> },
          ],
        },
        {
          path: 'level',
          children: [
            { path: 'list', element: <LevelList /> },
            { path: ':name/profile', element: <LevelProfile /> },
          ],
        },
        {
          path: 'gateways',
          children: [
            // { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'list', element: <GatewaysList /> },
            { path: ':name/profile', element: <GatewaysProfile /> },

            // { path: 'new', element: <GatewayCreate /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            // { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'list', element: <TransactionsList /> },
            { path: ':name/profile-transaction', element: <TransactionProfile /> },
          ],
        },
        {
          path: 'orders',
          children: [
            // { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'list', element: <OrdersList /> },
            { path: ':name/profile-ip-order', element: <IpOrderProfile /> },
          ],
        },
        {
          path: 'banks',
          children: [
            // { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'list', element: <BanksList /> },
          ],
        },
        {
          path: 'setting',
          element: <SiteSettings />,
          children: [
            // { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            // { path: 'list',, index: true },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'accessdenied', element: <PremissionAccess /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [{ element: <GeneralApp />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const PremissionAccess = Loadable(lazy(() => import('../pages/premissionAccess')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ROLE
const RoleList = Loadable(lazy(() => import('../pages/dashboard/role/RoleList')));
const RoleCreate = Loadable(lazy(() => import('../pages/dashboard/role/RoleCreate')));
const RoleProfile = Loadable(lazy(() => import('../pages/dashboard/role/RoleProfile')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/users/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/users/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/users/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/users/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/users/UserCreate')));
const UserAuthorization = Loadable(lazy(() => import('../pages/dashboard/users/UserAuthorization')));

// SETTINGS
const SiteSettings = Loadable(lazy(() => import('../pages/dashboard/settings/SiteSettings')));

// EXCHANGE
const ExchangeList = Loadable(lazy(() => import('../pages/dashboard/exchanges/ExchangeList')));
const ExchangeCreate = Loadable(lazy(() => import('../pages/dashboard/exchanges/ExchangeCreate')));
const ExchangeAuthorization = Loadable(lazy(() => import('../pages/dashboard/exchanges/ExchangeAuthorization')));

// ACCOUNT
const CryptoList = Loadable(lazy(() => import('../pages/dashboard/accounts/CryptoList')));
const CryptoProfile = Loadable(lazy(() => import('../pages/dashboard/accounts/CryptoProfile')));
const PerfectMoneyList = Loadable(lazy(() => import('../pages/dashboard/accounts/PerfectMoneyList')));
const PerfectMoneyProfile = Loadable(lazy(() => import('../pages/dashboard/accounts/PerfectMoneyProfile')));

// MARKET
const MarketProfile = Loadable(lazy(() => import('../pages/dashboard/market/MarketProfile')));
const MarketList = Loadable(lazy(() => import('../pages/dashboard/market/MarketList')));

// LEVEL
const LevelProfile = Loadable(lazy(() => import('../pages/dashboard/level/LevelProfile')));
const LevelList = Loadable(lazy(() => import('../pages/dashboard/level/LevelList')));

// GATEWAY
const GatewaysList = Loadable(lazy(() => import('../pages/dashboard/gateways/GatewaysList')));
const GatewaysProfile = Loadable(lazy(() => import('../pages/dashboard/gateways/GatewaysProfile')));

// TRANSACTIONS
const TransactionsList = Loadable(lazy(() => import('../pages/dashboard/transactions/TransactionsList')));
const TransactionProfile = Loadable(lazy(() => import('../pages/dashboard/transactions/TransactionProfile')));

// ORDERS
const OrdersList = Loadable(lazy(() => import('../pages/dashboard/orders/OrdersList')));
const IpOrderProfile = Loadable(lazy(() => import('../pages/dashboard/orders/IpOrderProfile')));

// BANKS
const BanksList = Loadable(lazy(() => import('../pages/dashboard/banks/BanksList')));

// TICKETS

// APP

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
