/* eslint-disable no-plusplus */
export function digitSperator(value) {
  if (value) {
    const str = value.toString().split('.');
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.').trim().replaceAll(' ', '');
  }
  return value;
}
export function ToFa(value) {
  const persionDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  return value.toString().replace(/\d/g, (x) => persionDigits[Number(x)]);
}

export function ToIranCurrency(num) {
  if (!num) return 0;
  if (num.toString().startsWith('0.')) return num;
  if (num) return digitSperator(parseFloat(num).toFixed(0));
  return num;
}
export function faNumToEnNum(value) {
  let str = value.replace(',', '');
  const persionDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  for (let i = 0; i < 10; i++) {
    str = str.replaceAll(persionDigits[i], i.toString());
  }
  return str;
}
