// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  role: {
    root: path(ROOTS_DASHBOARD, '/role'),
    new: path(ROOTS_DASHBOARD, '/role/new'),
    list: path(ROOTS_DASHBOARD, '/role/list'),
    edit: (name) => path(ROOTS_DASHBOARD, `/role/${name}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    authorization: path(ROOTS_DASHBOARD, '/user/authorization'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    edit_profile: (name) => path(ROOTS_DASHBOARD, `/user/${name}/profile`),
  },
  exchange: {
    root: path(ROOTS_DASHBOARD, '/exchange'),
    new: path(ROOTS_DASHBOARD, '/exchange/new'),
    list: path(ROOTS_DASHBOARD, '/exchange/list'),
    authorization: path(ROOTS_DASHBOARD, '/exchange/authorization-exchange'),

    edit: (name) => path(ROOTS_DASHBOARD, `/exchange/${name}/edit`),
  },
  account: {
    root: path(ROOTS_DASHBOARD, '/account'),
    crypto_list: path(ROOTS_DASHBOARD, '/account/crypto-list'),
    edit_crypto: (name) => path(ROOTS_DASHBOARD, `/account/${name}/profile-crypto`),
    perfect_list: path(ROOTS_DASHBOARD, '/account/perfect-list'),
    edit_pm: (name) => path(ROOTS_DASHBOARD, `/account/${name}/profile-pm`),
  },
  market: {
    root: path(ROOTS_DASHBOARD, '/market'),
    list: path(ROOTS_DASHBOARD, '/market/list'),
    edit_profile: (name) => path(ROOTS_DASHBOARD, `/market/${name}/profile`),
  },
  level: {
    root: path(ROOTS_DASHBOARD, '/level'),
    list: path(ROOTS_DASHBOARD, '/level/list'),
    edit_profile: (name) => path(ROOTS_DASHBOARD, `/level/${name}/profile`),
  },

  orders: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    list: path(ROOTS_DASHBOARD, '/orders/list'),
    editIpOrder: (name) => path(ROOTS_DASHBOARD, `/orders/${name}/profile-ip-order`),
  },
  transactions: {
    root: path(ROOTS_DASHBOARD, '/transactions'),
    list: path(ROOTS_DASHBOARD, '/transactions/list'),
    editTransaction: (name) => path(ROOTS_DASHBOARD, `/transactions/${name}/profile-transaction`),
  },
  banks: {
    root: path(ROOTS_DASHBOARD, '/banks'),
    list: path(ROOTS_DASHBOARD, '/banks/list'),
  },
  gateways: {
    root: path(ROOTS_DASHBOARD, '/gateways'),
    list: path(ROOTS_DASHBOARD, '/gateways/list'),
    edit_profile: (name) => path(ROOTS_DASHBOARD, `/gateways/${name}/profile`),
  },
  tickets: {
    root: path(ROOTS_DASHBOARD, '/tickets'),
    list: path(ROOTS_DASHBOARD, '/tickets/list'),
    all: path(ROOTS_DASHBOARD, '/tickets/all'),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/setting'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
};
