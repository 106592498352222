// routes
import { PATH_DASHBOARD } from 'routes/paths';
// components
import Label from 'components/Label';
import SvgIconStyle from 'components/SvgIconStyle';
import { endpoints } from '../../../data/endpoints';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  creditCard: getIcon('ic_creditcard'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  account: getIcon('ic_account'),
  market: getIcon('ic_market'),
  role: getIcon('ic_role'),
  level: getIcon('ic_levels'),
  gateway: getIcon('ic_gateway'),
  transactions: getIcon('ic_transactions'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'عمومی',
  //   items: [{ title: 'برنامه', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'مدیریت',
    items: [
      // ROLE
      {
        permissionPath: 'CONSTANT',

        title: 'نقش ها',
        path: PATH_DASHBOARD.role.root,
        icon: ICONS.role,
        children: [
          {
            permissionPath: `${endpoints.role.getAll}/{pageNumber}/{pageSize}`,
            title: 'لیست نقش ها',
            path: PATH_DASHBOARD.role.list,
          },
          {
            permissionPath: `${endpoints.role.add}`,
            title: 'ایجاد نقش جدید',
            path: PATH_DASHBOARD.role.new,
          },
        ],
      },
      // USER
      {
        permissionPath: 'CONSTANT',
        title: 'کاربران',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          // { title: 'پروفایل', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          {
            permissionPath: `${endpoints.users.getAll}/{pageNumber}/{pageSize}`,
            title: 'لیست کاربران',
            path: PATH_DASHBOARD.user.list,
          },
          {
            permissionPath: `${endpoints.usersAuth.getAll}/{pageNumber}/{pageSize}`,
            title: 'لیست بررسی احراز هویت',
            path: PATH_DASHBOARD.user.authorization,
          },
          // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
          // { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },
      // ECHANGES
      {
        permissionPath: 'CONSTANT',

        title: 'صرافی ها',
        path: PATH_DASHBOARD.exchange.root,
        icon: ICONS.analytics,
        children: [
          {
            permissionPath: `${endpoints.exchange.getAll}/{pageNumber}/{pageSize}`,
            title: 'لیست صرافی ها',
            path: PATH_DASHBOARD.exchange.list,
          },
          {
            permissionPath: `${endpoints.usersAuth.getAll}/{pageNumber}/{pageSize}`,
            title: 'لیست بررسی احراز هویت',
            path: PATH_DASHBOARD.exchange.authorization,
          },
        ],
      },
      // ACCOUNTS
      {
        permissionPath: 'CONSTANT',

        title: 'حساب ها',
        path: PATH_DASHBOARD.account.root,
        icon: ICONS.account,
        children: [
          {
            permissionPath: `${endpoints.cryptoExchange.getAll}`,
            title: 'لیست حساب های رمزارزی',
            path: PATH_DASHBOARD.account.crypto_list,
          },
          // {
          //   permissionPath: `${endpoints.perfectMoney.getAll}`,
          //   title: 'لیست حساب های پرفکت‌مانی',
          //   path: PATH_DASHBOARD.account.perfect_list,
          // },
        ],
      },
      // MARKETS
      {
        permissionPath: `${endpoints.markets.getAll}/{pageNumber}/{pageSize}`,

        title: 'مارکت ها',
        path: PATH_DASHBOARD.market.root,
        icon: ICONS.market,
        children: [{ permissionPath: 'CONSTANT', title: 'لیست مارکت ها', path: PATH_DASHBOARD.market.list }],
      },
      // Levels
      {
        permissionPath: `${endpoints.level.getAll}`,

        title: 'سطح ها',
        path: PATH_DASHBOARD.level.root,
        icon: ICONS.level,
        children: [{ permissionPath: 'CONSTANT', title: 'لیست سطح ها', path: PATH_DASHBOARD.level.list }],
      },
      // GATEWAYS
      // {
      //   title: 'درگاه های پرداخت',
      //   path: PATH_DASHBOARD.gateway.root,
      //   icon: ICONS.banking,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'لیست درگاه های پرداخت', path: PATH_DASHBOARD.gateway.list },
      //     { title: 'ایجاد درگاه پرداخت', path: PATH_DASHBOARD.gateway.new },
      //     // { title: 'لیست بررسی احراز هویت', path: PATH_DASHBOARD.exchange.authorization },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // Banks
      {
        permissionPath: `${endpoints.banks.getAll}/{pageNumber}/{pageSize}`,
        title: 'حساب های بانکی',
        path: PATH_DASHBOARD.banks.root,
        icon: ICONS.creditCard,
        children: [{ permissionPath: 'CONSTANT', title: 'لیست حساب های بانکی', path: PATH_DASHBOARD.banks.list }],
      },
      // GATEWAYS
      {
        permissionPath: `${endpoints.gateways.getAll}/{pageNumber}/{pageSize}`,
        title: 'درگاه های پرداخت',
        path: PATH_DASHBOARD.gateways.root,
        icon: ICONS.gateway,
        children: [{ permissionPath: 'CONSTANT', title: 'لیست درگاه های پرداخت', path: PATH_DASHBOARD.gateways.list }],
      },
      // ORDERS
      {
        permissionPath: `${endpoints.orders.getAll}/{pageNumber}/{pageSize}`,

        title: 'سفارشات',
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.booking,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          { permissionPath: 'CONSTANT', title: 'لیست سفارشات', path: PATH_DASHBOARD.orders.list },
        ],
      },
      // TRANSACTIONS
      {
        permissionPath: `${endpoints.transaction.getAll}/{pageNumber}/{pageSize}`,

        title: 'تراکنش ها',
        path: PATH_DASHBOARD.transactions.root,
        icon: ICONS.transactions,
        children: [{ permissionPath: 'CONSTANT', title: 'لیست تراکنش ها', path: PATH_DASHBOARD.transactions.list }],
        badge: true,
      },
      // SETTING
      // {
      //   title: 'تنظیمات',
      //   path: PATH_DASHBOARD.setting.root,
      //   icon: ICONS.blog,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     // { title: 'تنظیمات', path: PATH_DASHBOARD.setting.list },
      //     // { title: 'ایجاد درگاه پرداخت', path: PATH_DASHBOARD.gateway.new },
      //     // { title: 'لیست بررسی احراز هویت', path: PATH_DASHBOARD.exchange.authorization },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
    ],
  },
];

export default navConfig;
