import axios from 'axios';
import { toast } from 'react-hot-toast';

// config
// ----------------------------------------------------------------------

export const baseURL = 'https://api.changefa.net/';

export const publicApi = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
  },
});

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/auth/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export const authApi = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
  },
});

authApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.localStorage.removeItem('token');
      window.location.href = '/auth/login';
    } else if (error.response && error.response.status === 403) {
      toast.error('دسترسی غیر مجاز.');
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default api;
