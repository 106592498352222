export const endpoints = {
  auth: {
    signin: '/signin',
  },
  users: {
    getAll: '/user/all', // Protected
    details: '/user/details',
    edit: '/user/edit', // Protected
    addUserRole: '/user-role/add', // Protected
    editUserRole: '/user-role/edit', // Protected
    userProfileRole: '/user-role/profile', // Protected
  },
  usersAuth: {
    getAll: '/level/manualvalidations', // Protected
    getById: '/level/manualvalidations', //
    AcceptUpgradeToSecondLevel: '/level/upgrade/accept/level2', //
    DenyUpgradeToSecondLevel: '/level/upgrade/deny/level2', //
    AcceptBecomeExchanger: '/level/become-exchanger/accept', //
    DeclineBecomeExchanger: '/level/become-exchanger/decline', //
    AcceptAddExchanger: '/level/add-exchange/accept', //
    DeclineAddExchanger: '/level/add-exchange/decline', //
  },
  exchange: {
    getAll: '/exchange/list', // Protected
    details : "/exchange"
  },

  markets: {
    getAll: '/market/list', // Protected
    edit: '/market/update', // Protected
    details: '/market', // Protected
  },
  level: {
    getAll: '/level/list', // Protected
    edit: '/level/update', // Protected
  },
  cryptoExchange: {
    getAll: '/cryptoExchange/get-all', // Protected
    details: '/cryptoExchange', // Protected
    edit: '/cryptoExchange-account/update', // Protected
  },
  perfectMoney: {
    getAll: '/perfect-money/get-all', // Protected
    details: '/perfect-money', // Protected
    edit: '/perfect-money/update', // Protected
  },
  banks: {
    getAll: '/bank/account/all', // Protected
  },
  gateways: {
    getAll: '/gateway-account-setting/all',
    details: '/gateway-account-setting',
    edit: '/gateway-account-setting/edit',
  },

  role: {
    getAll: '/role/all', // Protected
    add: '/role-claim/add', // Protected
    delete: '/role-claim/delete', // Protected
    detail: '/role-claim/detail', // Protected
    edit: '/role-claim/edit', // Protected
  },
  permission: {
    getAll: '/permission/all',
  },

  // ORDERS
  ipOrders: {
    getAll: '/international-payment-order/all', // ...
    detail: '/international-payment-order/detail/full', // ...
  },
  orders: {
    getAll: '/order/all', // 500
    detail: '/international-payment-order/detail/full', // ...,
    states: "/Enum/OrderStates"
  },

  PendingTransactionsCount: {
    get: '/transactions/pending-count',
  },

  // TRANSACTIONS
  changeFaTransactions: {
    getAll: '/transactions/changefa-accounts/all', // Done
    detail: '/transaction/detail/full', // ...
  },
  transaction: {
    getAll: '/transactions/all', // Done
    detail: '/transaction/detail/full', // ...
    states: "/Enum/TransactionStates"
  },
  confirmation: {
    ipOrderByAdmin: '/international-payment-order/confirm-by-admin', // ...
  },
};
